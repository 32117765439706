const logo = new URL('tiki-cat-logo.svg', import.meta.url);

export function Logo() {
  return (
    <img
      src={logo}
      alt='Tiki Cat'
      width='300px'
      className='float-lg-end mx-md-auto d-block'
    />
  );
}
