export function IntroText() {
  return (
    <>
      <h1 className='display-2'>Tiki Cat Lounge</h1>
      <p className='lead'>The best backyard lounge around</p>

      <p>
        Our goal is to create an inviting outdoor space where we can spend time
        with friends and family. We provide the atmosphere of a lounge, the
        drinks of a craft cocktail bar, and the conversation of the Algonquin
        Round Table (not really, but a person can dream).
      </p>
      <p>
        This is our backyard. Not a public place. By invite only. Do we know
        you?
      </p>
    </>
  );
}
