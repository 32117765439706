import { CarouselItem } from './CarouselItem';

const activeClass = (isActive) => (isActive ? 'active' : '');

const photos = [];
photos.push(new URL('tiki-cat-photo-1.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-2.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-3.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-4.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-5.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-6.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-7.jpg', import.meta.url));
photos.push(new URL('tiki-cat-photo-8.jpg', import.meta.url));



const carouselItems = photos.map((item, index) => (
  <CarouselItem key={index} photo={item} isActive={activeClass(0 === index)} />
));

const carouselIndicators = [...photos.keys()].map((index) => (
  <button
    key={index}
    type='button'
    data-bs-target='#photos'
    data-bs-slide-to={index}
    className={activeClass(0 === index)}
    aria-current='true'
    aria-label={`Slide ${index + 1}`}
  ></button>
));

export function Carousel() {
  return (
    <div id='photos' className='carousel slide mb-3' data-bs-ride='carousel'>
      <div className='carousel-indicators'>{carouselIndicators}</div>
      <div className='carousel-inner rounded'>{carouselItems}</div>
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#photos'
        data-bs-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#photos'
        data-bs-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  );
}
